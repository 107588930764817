//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import NormalHead from '../header.vue'
  export default {
    name: "shopDetail",
    created(){
      this.Bus.$on('changeStuted', Stuted => {  
          this. popupStuted =  Stuted
      }); 
      this.Bus.$on('shopItem',item => {
          this.shopDetail = item
      })

    },
    methods:{
      goMap:function () {
        this.$router.push({
          name: 'IshopMap',
//          query: {N: this._data.shopData.fullName, X: this._data.shopData.el, Y: this._data.shopData.nl}
        })
      }
    },
    data() {
      return {
        popupStuted: false,
        headValue: {
          name: '餐厅',
          close: true,
        },
        shopDetail:[]
      }
    },
    components: {
      NormalHead
    }
  }

